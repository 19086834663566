import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import { fetchResearchEdit } from "./APICalls";
import { useNavigate } from "react-router-dom";
//import SETTINGS from "../Config/settings";
import { useDataResearch } from "./researchsActions";

import { getTranslations } from "../Languages/i18n";
const langData = getTranslations();

export const EditResearch = ({ accessToken, refreshToken }) => {
  const { _id } = useParams();
  const data = useDataResearch();
  const navigate = useNavigate();
  const [dataResearch, setDataResearch] = useState(null);
  const [formDataResearch, setFormDataResearch] = useState(null);


  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setFormDataResearch((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    
    };


  useEffect(() => {
    const data_research = {
      title: data?.title ? data.title : null,
      technique: data?.technique ? data.technique : null,
      materials: data?.materials ? data.materials : null,
      creation_date: data?.creation_date ? data.creation_date : null,
      acquisition_form: data?.acquisition_form ? data.acquisition_form : null,
      acquisition_source: data?.acquisition_source
        ? data.acquisition_source
        : null,
      acquisition_date: data?.acquisition_date ? data.acquisition_date : null,
      firm: data?.firm ? data.firm : null,
      firm_description: data?.firm_description ? data.firm_description : null,
      short_description: data?.short_description
        ? data.short_description
        : null,
      formal_description: data?.formal_description
        ? data.formal_description
        : null,
      observation: data?.observation ? data.observation : null,
      publications: data?.publications ? data.publications : null,
      card: data?.card ? data.card : null,
      keywords: data?.keywords ? data.keywords : null,
      authors: data?.authors_info
        ? data.authors_info.map((author) => author.title).join(", ")
        : null,
      place_of_creation: data?.place_of_creation_info
        ? data.place_of_creation_info.map((place) => place.title).join(", ")
        : null,
      involved_creation: data?.involved_creation_info
        ? data.involved_creation_info
            .map((involved) => involved.title)
            .join(", ")
        : null,
    };
    setDataResearch(data_research);
    setFormDataResearch(data_research);

  }, [data, setDataResearch, setFormDataResearch]); 

  return (
    <div>
      {console.log("data piece research", data?.title ? data.title : null)}
      {data?.title ? <h1>{data.title}</h1> : null}

      {dataResearch
        ? Object.keys(dataResearch ? dataResearch : {}).map((key, index) => {
            return (
              <p key={index}>
                {key}: {JSON.stringify(dataResearch[key])}
              </p>
            );
          })
        : null}

      {Object.keys(data ? data : {}).map((key, index) => {
        return (
          <p key={index}>
            {key}: {JSON.stringify(data[key])}
          </p>
        );
      })}
        <div className="container">
    <form>
      <div className="card p-4" style={{ background: "#abcc" }}>
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="title" className="form-label">
              {langData.pieceDetailDescriptors.research.title}
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={formDataResearch?.title ? formDataResearch?.title : ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <label htmlFor="technique" className="form-label">
              {langData.pieceDetailDescriptors.research.technique}
            </label>
            <input
              type="text"
              className="form-control"
              id="technique"
              value={formDataResearch?.technique ? formDataResearch?.technique : ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="materials" className="form-label">
              {langData.pieceDetailDescriptors.research.materials}
            </label>
            <input type="text" className="form-control" id="materials" value={formDataResearch?.materials || ""} onChange={handleInputChange} />
          </div>
          <div className="col">
            <label htmlFor="creation_date" className="form-label">
              {langData.pieceDetailDescriptors.research.creation_date}
            </label>
            <input type="text" className="form-control" id="creation_date" value={formDataResearch?.creation_date || ""} onChange={handleInputChange} />
          </div>
        </div>
        
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="acquisition_form" className="form-label">
              {langData.pieceDetailDescriptors.research.acquisition_form}
            </label>
            <input type="text" className="form-control" id="acquisition_form" value={formDataResearch?.acquisition_form || ""} onChange={handleInputChange} />
          </div>
          <div className="col">
            <label htmlFor="acquisition_source" className="form-label">
              {langData.pieceDetailDescriptors.research.acquisition_source}
            </label>
            <input type="text" className="form-control" id="acquisition_source" value={formDataResearch?.acquisition_source || ""} onChange={handleInputChange} />
          </div>
          <div className="col">
            <label htmlFor="acquisition_date" className="form-label">
              {langData.pieceDetailDescriptors.research.acquisition_date}
            </label>
            <input type="text" className="form-control" id="acquisition_date" value={formDataResearch?.acquisition_date || ""} onChange={handleInputChange} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="firm" className="form-label">
              {langData.pieceDetailDescriptors.research.firm}
            </label>
            <input type="text" className="form-control" id="firm" value={formDataResearch?.firm || ""} onChange={handleInputChange} />
          </div>
          <div className="col">
            <label htmlFor="firm_description" className="form-label">
              {langData.pieceDetailDescriptors.research.firm_description}
            </label>
            <input type="text" className="form-control" id="firm_description" value={formDataResearch?.firm_description || ""} onChange={handleInputChange} />
          </div>
        </div>
        
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="short_description" className="form-label">
              {langData.pieceDetailDescriptors.research.short_description}
            </label>
            <input type="text" className="form-control" id="short_description" value={formDataResearch?.short_description || ""} onChange={handleInputChange} />
          </div>
          <div className="col">
            <label htmlFor="formal_description" className="form-label">
              {langData.pieceDetailDescriptors.research.formal_description}
            </label>
            <input type="text" className="form-control" id="formal_description" value={formDataResearch?.formal_description || ""} onChange={handleInputChange} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="observation" className="form-label">
              {langData.pieceDetailDescriptors.research.observation}
            </label>
            <input type="text" className="form-control" id="observation" value={formDataResearch?.observation || ""} onChange={handleInputChange} />
          </div>
          <div className="col">
            <label htmlFor="publications" className="form-label">
              {langData.pieceDetailDescriptors.research.publications}
            </label>
            <input type="text" className="form-control" id="publications" value={formDataResearch?.publications || ""} onChange={handleInputChange} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="card" className="form-label">
              {langData.pieceDetailDescriptors.research.card}
            </label>
            <input type="text" className="form-control" id="card" value={formDataResearch?.card || ""} onChange={handleInputChange} />
          </div>
          <div className="col">
            <label htmlFor="keywords" className="form-label">
              {langData.pieceDetailDescriptors.research.keywords}
            </label>
            <input type="text" className="form-control" id="keywords" value={formDataResearch?.keywords || ""} onChange={handleInputChange} />
          </div>
        </div>
      </div>
    </form>
  </div>
    </div>
  );
};
