import { useParams,  Outlet } from 'react-router-dom';
import { createContext, useContext,useState ,useEffect} from 'react';

import { fetchInventoryEdit } from './APICalls';
const DataContext = createContext();



export const InventoryEdit = ({ accessToken, refreshToken }) => {

    const { _id } = useParams();
    //const navigate = useNavigate();
    const [Data, setData] = useState();
    const [Documents, setDocuments] = useState();
    useEffect(()=>{

        fetchInventoryEdit(accessToken,refreshToken, _id)
    .then(data =>{
        //console.log(data,"datarecien")
        setData(data); 
        setDocuments(data["documents"]);
    })
    .catch(error =>{
        console.error("Error inesperado", error);
    });

    },[_id, accessToken, refreshToken]);    
    
    return (
        <DataContext.Provider value={Data}>
            
            <br/>
            {Documents?.map((document)=>{
                return document.file_name;
            })}
           
            <Outlet />
        </DataContext.Provider>
    )
}

export const useData = () => useContext(DataContext);